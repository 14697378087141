<div class="row">
  <div class="col-md-12">
    <div class="card" style="background-color: #e9e9ef !important;">
      <div class="card-body">
        <div class="details_top">
          <h2 style="text-align: center;">{{ campaignData.name }}</h2>
          <h3 style="text-align: center;">{{ campaignData.platform }}</h3>
          <h4 style="text-align: center;">
            <a style="color: blue;" [routerLink]="['/pages/client-details', campaignData?.client?.id]" class="" title="view client details">{{campaignData?.client?.firstName}} {{campaignData?.client?.lastName}}</a>
          </h4>
          <div class="row row-gap-4 d-flex align-items-stretch" style="margin-top: 25px;">
            <div class="location_info col-12 " [ngClass]="(isExternal) ? 'col-xl-4' : 'col-xl-3'">
              <div class="card campaign-card">
                <h5 class="card-title">Location Info:</h5>
                <div class="card-body">
                  <p><strong>Address:</strong><br *ngIf="campaignData?.locationAddress" />
                    {{campaignData?.locationAddress}}</p>
                  <p><strong>Email:</strong> {{campaignData?.locationEmail}}</p>
                  <p><strong>Phone:</strong> {{campaignData?.locationPhone}}</p>
                  <p> <span *ngIf="campaignData?.crmName"><strong>CRM:</strong> {{campaignData?.crmName}}<br /></span>
                  <span *ngIf="campaignData?.crmId"><strong>CRM ID:</strong> {{campaignData?.crmId}}</span></p>
                </div>
              </div>
            </div>
            <div class="campaign_info col-12 " [ngClass]="(isExternal) ? 'col-xl-4' : 'col-xl-3'">
              <div class="card campaign-card">
                <h5 class="card-title">Campaign Info:</h5>
                <div class="card-body">
                  <p><strong>DMS:</strong> {{ campaignData?.dmsName }}</p>
                  <p><strong>Stage:</strong> {{ campaignData?.stageId | P_CampaignStageTitle }}</p>
                  <p><strong>Active Subscription? </strong> {{ campaignData.subscriptionActive | P_Boolean }}</p>
                  <p *ngIf="!isExternal && (mostRecentOrder !== {})"><strong>Most Recent Order Status: </strong>
                    {{mostRecentOrder.paid ? 'Paid' : 'Unpaid'}}</p>
                  <div style="margin-bottom: 10px;" *ngIf="!isExternal" >
                    <h6>Ad Account Info ({{ campaignData.platform }}):</h6>
                    <table id=adAcccounts style="width: 100%;" *ngIf="campaignData?.campaignAdAccounts?.length != 0">
                        <tr>
                          <td style="width: 35%;"><strong>Ad Account ID</strong></td>
                          <td style="width: 35%;"><strong>Ad Campaign ID</strong></td>
                          <td style="width: 30%;"><strong>Ad Label</strong></td>
                        </tr>
                        <tr *ngFor="let ad of campaignData.campaignAdAccounts;">
                          <td>{{ad.platformAccountID}}</td>
                          <td>{{ad.platformCampaignID}}</td>
                          <td>{{ad.adLabel}}</td>
                        </tr>
                    </table>
                    <!-- <p><strong>{{ campaignData.platform }} Ads Account ID:</strong> {{ campaignData.platformAccountId }}<br />
                      <strong>{{ campaignData.platform }} Ads Campaign ID:</strong> {{ campaignData.platformCampaignId }}</p> -->
                  </div>
                  <div *ngIf="campaignData?.employees?.length !== 0" style="padding-bottom: 15px;">
                    <h6>Employee Users:</h6>
                    <span *ngFor="let e of campaignData.employees; let last = last">
                      {{ e.firstName }} {{ e.lastName }}<ng-container *ngIf="!last">, </ng-container>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="campaign_kpis col-12 " [ngClass]="(isExternal) ? 'col-xl-4' : 'col-xl-3'">
              <div class="card campaign-card">
                <h5 class="card-title">Campaign KPIs:</h5>
                <div class="card-body">
                  <iframe [src]="iframeUrl" width="600" height="260"></iframe>
                </div>
              </div>
            </div>
            <div *ngIf="!isExternal" class="additional_info col-12 col-xl-3">
              <div class="card campaign-card">
                <h5 class="card-title">Internal Info:</h5>
                <div class="card-body newlines">
                  <p><strong>Campaign ID:</strong> {{ campaignData?.campaignId }}</p>
                  <p><strong>Campaign Notes:</strong><br *ngIf="campaignData?.opportunityNotes" />
                    {{ campaignData.opportunityNotes }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dv-custom-navs">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" style="display: flex; flex-direction: row; flex-wrap: nowrap;">
            <li [ngbNavItem]="1" [ngStyle]="{'z-index': (active === 1) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.notes && roleOptions.notes.canRead)">
              <a ngbNavLink>
                <i-feather name="paperclip"></i-feather><label>NOTES</label>
              </a>
              <ng-template ngbNavContent>
                <app-notes [iCanDelete]="isAdmin || (roleOptions.notes && roleOptions.notes.canDelete)"
                  [iCanWrite]="isAdmin || (roleOptions.notes && roleOptions.notes.canWrite)" [iIsAdmin]="isAdmin"
                  [iIsExternal]="isExternal" [iHideActionColName]="true" [isGooglePlatform] = "campaignData.platform == 'Google'" [iParentId]="campaignData.id" [iType]="1">
                </app-notes>
              </ng-template>
            </li>
            <li style="left: -7.5px;" [ngStyle]="{'z-index': (active === 2) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.tasks && roleOptions.tasks.canRead)" [ngbNavItem]="2">
              <a ngbNavLink>
                <i-feather name="list"></i-feather><label>TASKS</label>
              </a>
              <ng-template ngbNavContent>
                <div style="background-color: white !important;">
                  <div style="display: flex; padding: 8px 16px 8px 16px; justify-content: end;">
                    <div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center;">
                      <button class="blue-button" style="float: right; margin-right: 8px;" type="button" (click)="onCampaignCheck()">
                        CAMPAIGN CHECK
                      </button>
                      <button class="blue-button" type="button" (click)="onAddTask()">ADD TASK</button>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div (click)="changeShowTasks()" style="padding-left: 16px; padding-right: 16px;">
                        <h2 [style.color]="showTasks ? '#495057' : '#EBE9EB'" [style.border-bottom]="showTasks ? '2px solid rgb(73, 80, 87)' : '2px solid #EBE9EB'" style="margin-bottom: 0px !important; padding: 0px 16px 8px 16px; cursor: pointer;">TO-DO</h2>
                      </div>
                      <div (click)="changeShowCompletedTasks()" style="padding-left: 16px; padding-right: 16px;">
                        <h2 [style.color]="showCompletedTasks ? '#495057' : '#EBE9EB'" [style.border-bottom]="showCompletedTasks ? '2px solid rgb(73, 80, 87)' : '2px solid #EBE9EB'" style="padding: 0px 16px 8px 16px; margin-bottom: 0px !important; cursor: pointer;">COMPLETED</h2>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" style="padding-right: 16px;">
                      <input type="checkbox" id="showMyTasksCheckbox" [(ngModel)]="showOnlyMyTasks" (change)="onShowMyTasksChange()"
                      value="Show Only My Tasks"><label style="margin-left: 8px !important; margin-bottom: 0px !important;" for="showMyTasksCheckbox">Show Only My Tasks</label>
                    </div>
                  </div>
                  <app-tasks-datatable *ngIf="showTasks" [iCanRead]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canRead)"
                    [iCanWrite]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canWrite)"
                    [iCanDelete]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canDelete)"
                    (oRowClick)="onTaskRowClick($event)" [iSortColumn]="'title'" [iColumns]="columnsTasks"
                    [iShowActions]="false" [iData$]="tasksData">
                  </app-tasks-datatable>
                  <app-tasks-datatable *ngIf="showCompletedTasks" [iCanRead]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canRead)"
                    [iCanWrite]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canWrite)"
                    [iCanDelete]="isAdmin || (roleOptions.tasks && roleOptions.tasks.canDelete)"
                    (oRowClick)="onTaskRowClick($event)" [iSortColumn]="'completedOn'" [iSortDirection]="'desc'"
                    [iColumns]="completedTasksColumns" [iShowActions]="false" [iData$]="completedTasksData" [isCompleted]="true">
                  </app-tasks-datatable>
                </div>
              </ng-template>
            </li>
            <li style="left: -15px;" [ngStyle]="{'z-index': (active === 3) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.subscriptions && roleOptions.subscriptions.canRead)" [ngbNavItem]="3">
              <a ngbNavLink>
                <i-feather name="refresh-cw"></i-feather><label>SUBSCRIPTIONS</label>
              </a>
              <ng-template ngbNavContent>
                <app-subscription-shared [iCampaignId]="campaignData.id" [iClientId]="campaignData?.client?.id">
                </app-subscription-shared>
              </ng-template>
            </li>
            <li (click)="loadOrders();" style="left: -22.5px;" [ngStyle]="{'z-index': (active === 4) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.subscriptions && roleOptions.subscriptions.canRead)" [ngbNavItem]="4">
              <a ngbNavLink>
                <i-feather name="file-text"></i-feather><label>ORDERS</label>
              </a>
              <ng-template ngbNavContent>
                <app-campaign-datatable
                  [iCanRead]="doWhat.canRead" [iShowViewAction]="false" [iColumns]="orderColumns"
                  [iHideHeaderActions]="false" (oRowClick)="onClick($event)" [iData$]="checkingOrders"
                  [iShowSelect]="false" iSortColumn="numericId" [iShowExport]="false" [iShowEditAction]="false" [iHideHeaderActions]="false" [iTotalCount]="checkingOrders.length" 
                  iSortDirection="desc">
                </app-campaign-datatable>
              </ng-template>
              <!-- (oLoadDataFromServer)="onLoadingDataOrders($event)" -->
            </li>
            <li style="left: -30px;" [ngStyle]="{'z-index': (active === 5) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.dashboard && roleOptions.dashboard.canRead)" [ngbNavItem]="5">
              <a ngbNavLink>
                <i-feather name="layout"></i-feather><label>DASHBOARD</label>
              </a>
              <ng-template ngbNavContent>
                <app-campaign-details-report-view [CampaignData]="campaignData" [ReportingPeriods]="reportingPeriods">
                </app-campaign-details-report-view>
              </ng-template>
            </li>
            <li style="left: -37.5px;" (click)="filterLeads();" [ngStyle]="{'z-index': (active === 6) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.dashboard && roleOptions.dashboard.canRead)" [ngbNavItem]="6">
              <a ngbNavLink>
                <i-feather name="bar-chart-2"></i-feather><label>LEADS</label>
              </a>
              <ng-template ngbNavContent>
                <div style="background-color: white; padding: 8px;">
                  <app-campaign-datatable [iCanRead]="doWhat.canRead" [iCanWrite]="doWhat.canWrite" [iCanDelete]="doWhat.canDelete"
                      [iColumns]="columns" (oDeleteRows)="onDelete($event)" [iRowLinkHandler]="getRowLink"
                      [iTotalCount]="leadsTotalCount" [iHideHeaderActions]="true"
                      [iData$]="leadsData" (oLoadDataFromServer)="onLoadingData($event)" [iShowSelect]="false"
                      iSortColumn="createdat" iSortDirection="desc" [iShowSelect]="true">
                  </app-campaign-datatable>
                </div>
                <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#000" type="ball-spin-clockwise" [fullScreen]="true">
                    <p style="color: white">Data export in progress this may take several minutes.</p>
                </ngx-spinner> -->
              </ng-template>
            </li>
            <li style="left: -45px;" [ngStyle]="{'z-index': (active === 7) ? '5' : '0'}" [ngbNavItem]="7" *ngIf="isAdmin || (roleOptions.tags && roleOptions.tags.canRead)">
              <a ngbNavLink>
                <i-feather name="tag"></i-feather><label>TAGS</label>
              </a>
              <ng-template ngbNavContent>
                <app-tags-tab [iCanWrite]="isAdmin || (roleOptions.tags && roleOptions.tags.canWrite)"
                  [iParentId]="campaignData.id" [iType]="1"></app-tags-tab>
              </ng-template>
            </li>
            <li style="left: -52.5px;" [ngStyle]="{'z-index': (active === 8) ? '5' : '0'}" *ngIf="isAdmin || (roleOptions.audit && roleOptions.audit.canRead)" [ngbNavItem]="8">
              <a ngbNavLink>
                <i-feather name="file-text"></i-feather><label>AUDIT</label>
              </a>
              <ng-template ngbNavContent>
                <app-campaign-audit [iCanWrite]="isAdmin || (roleOptions.audit && roleOptions.audit.canWrite)"
                  [iCampaignId]="campaignData.id"> </app-campaign-audit>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</div>
